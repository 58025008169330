import { Grid, Typography, Container, List, Box, ListItem, ListItemText, Button, Divider, TextField, IconButton } from '@mui/material';
import InstagramIcon from '@mui/icons-material/Instagram';
import YouTubeIcon from '@mui/icons-material/YouTube';
import TwitterIcon from '@mui/icons-material/Twitter';
import FacebookIcon from '@mui/icons-material/Facebook';
import EmailIcon from '@mui/icons-material/Email';
import PhoneIcon from '@mui/icons-material/Phone';
import VisaIcon from '@mui/icons-material/CreditCard';
import PaypalIcon from '@mui/icons-material/CreditCard';
import LocationOnIcon from '@mui/icons-material/LocationOn';
import AccessTimeIcon from '@mui/icons-material/AccessTime';
import AppleIcon from '@mui/icons-material/Apple';
import MasterCardIcon from '@mui/icons-material/CreditCard';
import TikTokIcon from '@mui/icons-material/MusicNote';
import { useNavigate } from 'react-router-dom';

const typographyStyles = {
    fontFamily: "Roboto",
    fontSize: "16px",
    fontWeight: 500,
    lineHeight: "29.44px",
    textAlign: "left",
    textDecoration: "none"
}

export default function Footer() {
    const navigate = useNavigate();
    return (
        <Box sx={{ bgcolor: '#f8f9fa', pt: 6, pb: 3 }}>
      <Container maxWidth="xl">
        {/* Main Footer Content - 5 Columns */}
        <Grid container spacing={2}>
          {/* Brand and Contact Info */}
          <Grid item xs={12} md={2.4}>
          <Box
            component="img"
            sx={{ width: 200, height: "auto" }}
            src={`${process.env.PUBLIC_URL}/assets/images/swag-logo.png`}
            alt="Swag"
          />


            
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <LocationOnIcon sx={{ mr: 1, color: 'text.secondary', fontSize: 'small' }} />
              <Typography variant="body2" color="text.secondary">
                Swag Jewelers Inc. 4301 Martinique Circle
                            Suite B1
                            Coconut Creek, FL 33066
              </Typography>
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <PhoneIcon sx={{ mr: 1, color: 'text.secondary', fontSize: 'small' }} />
              <Typography variant="body2" color="text.secondary">
                +1(954)-990-3240
              </Typography>
            </Box>
            
            <Box sx={{ display: 'flex', alignItems: 'center', mb: 1 }}>
              <EmailIcon sx={{ mr: 1, color: 'text.secondary', fontSize: 'small' }} />
              <Typography variant="body2" color="text.secondary">
                admin@swagjewelers.com
              </Typography>
            </Box>
          </Grid>
          
          {/* My Account */}
          <Grid item xs={12} sm={6} md={2.4}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              My Account
            </Typography>
            <List sx={{ p: 0 }}>
              <ListItem sx={{ py: 0.5, px: 0 }}>
                <ListItemText 
                  primary="Login/Register" 
                  primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }} 
                />
              </ListItem>
              <ListItem sx={{ py: 0.5, px: 0 }}>
                <ListItemText 
                  primary="Wishlist" 
                  primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }} 
                />
              </ListItem>
              <ListItem sx={{ py: 0.5, px: 0 }}>
                <ListItemText 
                  primary="Track Your Orders" 
                  primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }} 
                />
              </ListItem>
              <ListItem sx={{ py: 0.5, px: 0 }}>
                <ListItemText 
                  primary="Checkout" 
                  primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }} 
                />
              </ListItem>
            </List>
          </Grid>
          
          {/* Our Policies */}
          <Grid item xs={12} sm={6} md={2.4}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Our Policies
            </Typography>
            <List sx={{ p: 0 }}>
              <ListItem sx={{ py: 0.5, px: 0 }}>
                <ListItemText 
                  onClick={() => navigate("/shipping-policy")}
                  sx={{ cursor: "pointer" }}
                  primary="Shipping & Delivery" 
                  primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }} 
                />
              </ListItem>
              <ListItem sx={{ py: 0.5, px: 0 }}>
                <ListItemText 
                  onClick={() => navigate("/return-policy")}
                  sx={{ cursor: "pointer" }}
                  primary="Return Policy" 
                  primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }} 
                />
              </ListItem>
              <ListItem sx={{ py: 0.5, px: 0 }}>
                <ListItemText
                  onClick={() => navigate("/terms-condition")}
                  sx={{ cursor: "pointer" }}
                  primary="Terms & Conditions" 
                  primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }} 
                />
              </ListItem>
              <ListItem sx={{ py: 0.5, px: 0 }}>
                <ListItemText
                  onClick={() => navigate("/privacy-policy")}
                  sx={{ cursor: "pointer" }}
                  primary="Privacy Policy" 
                  primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }} 
                />
              </ListItem>
            </List>
          </Grid>
          
          {/* Customer Care */}
          <Grid item xs={12} sm={6} md={2.4}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Customer Care
            </Typography>
            <List sx={{ p: 0 }}>
              <ListItem sx={{ py: 0.5, px: 0 }}>
                <ListItemText 
                  primary="FAQs" 
                  primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }} 
                />
              </ListItem>
              <ListItem sx={{ py: 0.5, px: 0 }}>
                <ListItemText 
                  primary="Terms of Service" 
                  primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }} 
                />
              </ListItem>
              <ListItem sx={{ py: 0.5, px: 0 }}>
                <ListItemText 
                  primary="Privacy Policy" 
                  primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }} 
                />
              </ListItem>
              <ListItem sx={{ py: 0.5, px: 0 }}>
                <ListItemText 
                  primary="Gift Card" 
                  primaryTypographyProps={{ variant: 'body2', color: 'text.secondary' }} 
                />
              </ListItem>
            </List>
          </Grid>
          
          {/* Newsletter */}
          <Grid item xs={12} sm={6} md={2.4}>
            <Typography variant="h6" sx={{ mb: 2 }}>
              Subscribe Our Newsletter
            </Typography>
            <Typography variant="body2" color="text.secondary" paragraph>
              Join our newsletter for exclusive updates, special offers, and the latest news delivered straight to your inbox!
            </Typography>
            <Box sx={{ mb: 2 }}>
              <TextField
                fullWidth
                placeholder="Enter your email"
                variant="outlined"
                size="small"
                sx={{ mb: 1 }}
              />
              <Button 
                fullWidth
                variant="contained" 
                sx={{ 
                  bgcolor: '#000', 
                  '&:hover': { bgcolor: '#333' },
                  borderRadius: 100
                }}
              >
                Subscribe
              </Button>
            </Box>
          </Grid>
        </Grid>
        
        <Divider sx={{ my: 4 }} />
        
        {/* Middle Section */}
        <Grid container spacing={2} sx={{ mb: 4 }}>
          <Grid item xs={12} md={4}>
            <Box sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 1 }}>
              <Typography variant="body2" sx={{ color: 'text.secondary' }}>We Accept Payments</Typography>
            </Box>
            <Box sx={{ display: 'flex', gap: 1 }}>
              <VisaIcon />
              <AppleIcon />
              <MasterCardIcon />
              <PaypalIcon />
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: 'center' }}>
              <Typography variant="h6" gutterBottom>
                Thank You For Choosing Us!
              </Typography>
              <Typography variant="body2" color="text.secondary">
                We appreciate your support and are dedicated to providing you with
                the best products and service. We look forward to serving you again!
              </Typography>
            </Box>
          </Grid>
          <Grid item xs={12} md={4}>
            <Box sx={{ textAlign: 'right' }}>
              <Typography variant="body2" sx={{ mb: 1 }}>Follow Us</Typography>
              <Box sx={{ display: 'flex', justifyContent: 'flex-end', gap: 1 }}>
                <IconButton size="small">
                  <FacebookIcon fontSize="small" />
                </IconButton>
                <IconButton size="small">
                  <InstagramIcon fontSize="small" />
                </IconButton>
                <IconButton size="small">
                  <TwitterIcon fontSize="small" />
                </IconButton>
                <IconButton size="small">
                  <YouTubeIcon fontSize="small" />
                </IconButton>
                <IconButton size="small">
                  <TikTokIcon fontSize="small" />
                </IconButton>
              </Box>
            </Box>
          </Grid>
        </Grid>
        
        <Grid container justifyContent="space-between" alignItems="center">
          <Grid item>
            <Typography variant="body2" color="text.secondary">
              Copyright © 2025 Swag Jewelers Inc. All Rights Reserved.
            </Typography>
          </Grid>
          <Grid item>
            <Button startIcon="🇺🇸" endIcon="▼" size="small" sx={{ color: 'text.secondary' }}>
              United States | USD $
            </Button>
            <Button startIcon="🇬🇧" endIcon="▼" size="small" sx={{ color: 'text.secondary' }}>
              English
            </Button>
          </Grid>
        </Grid>
      </Container>
    </Box>
    );
}