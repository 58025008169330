import React, { useState } from "react";
import { AppBar, Toolbar, Divider, Box, Typography } from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import PersonOutlineIcon from "@mui/icons-material/PersonOutline";
import FavoriteBorderIcon from "@mui/icons-material/FavoriteBorder";
import ShoppingBagOutlinedIcon from "@mui/icons-material/ShoppingBagOutlined";

const TopBar = ({ setLoginOpen }) => {
  const [visible, setVisible] = useState(true);
  return (
    <>
      <Box
        style={{
          position: "fixed",
          width: "100%",
          zIndex: 1000,
          transition: "transform 0.3s ease-in-out",
          transform: visible ? "translateY(0)" : "translateY(-100%)",
        }}
      >
        <Box
          style={{
            display: "flex",
            backgroundColor: "white",
            alignItems: "center",
            justifyContent: "space-between",
            height: "35px",
            maxWidth: "1370px",
            margin: "26px auto",
            borderRadius: "8px",
            padding: "35px",
            boxShadow: visible ? "0 4px 12px rgba(0,0,0,0.1)" : "none",
          }}
        >
          <Box style={{ display: "flex", alignItems: "center" }}>
            <a
              href="/"
              style={{
                color: "#333",
                textDecoration: "none",
                marginRight: "30px",
                fontSize: "12px",
                fontWeight: 500,
                borderBottom: "2px solid #000",
                paddingBottom: "3px",
              }}
            >
              Home
            </a>
            <a
              href="#"
              style={{
                color: "#333",
                textDecoration: "none",
                marginRight: "30px",
                fontSize: "12px",
                fontWeight: 500,
                borderBottom: "2px solid #000",
                paddingBottom: "3px",
              }}
            >
              Collection
            </a>
            <a
              href="/category/11993"
              style={{
                color: "#333",
                textDecoration: "none",
                marginRight: "30px",
                fontSize: "12px",
                fontWeight: 500,
                borderBottom: "2px solid #000",
                paddingBottom: "3px",
              }}
            >
              Products
            </a>
            <a
              href="#"
              style={{
                color: "#333",
                textDecoration: "none",
                marginRight: "30px",
                fontSize: "12px",
                fontWeight: 500,
                borderBottom: "2px solid #000",
                paddingBottom: "3px",
              }}
            >
              Pages
            </a>
            <a
              href="#"
              style={{
                color: "#333",
                textDecoration: "none",
                marginRight: "30px",
                fontSize: "12px",
                fontWeight: 500,
                borderBottom: "2px solid #000",
                paddingBottom: "3px",
              }}
            >
              Blog
            </a>
          </Box>
          <Box
            component="img"
            sx={{ width: 165, height: "auto" }}
            src={`${process.env.PUBLIC_URL}/assets/images/swag-logo.png`}
            alt="Swag"
          />
          <Box style={{ display: "flex", alignItems: "center" }}>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
                marginRight: "20px",
              }}
            >
              <SearchIcon sx={{ fontSize: 20 }} />
              <Typography style={{ marginLeft: "8px", fontSize: "12px" }}>
                Search
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
                marginRight: "20px",
              }}
            >
              <PersonOutlineIcon sx={{ fontSize: 20 }} />
              <Typography style={{ marginLeft: "8px", fontSize: "12px" }}>
                Account
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
                marginRight: "20px",
              }}
            >
              <FavoriteBorderIcon sx={{ fontSize: 20 }} />
              <Typography style={{ marginLeft: "8px", fontSize: "12px" }}>
                Wishlist
              </Typography>
            </Box>
            <Box
              style={{
                display: "flex",
                alignItems: "center",
                borderRadius: "4px",
                marginRight: "20px",
              }}
            >
              <ShoppingBagOutlinedIcon sx={{ fontSize: 20 }} />
              <Typography style={{ marginLeft: "8px", fontSize: "12px" }}>
                Cart
              </Typography>
            </Box>
          </Box>
        </Box>
      </Box>
    </>
  );
};

export default TopBar;
